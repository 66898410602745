<template>
  <!-- <div class='notFind'>
    <img src="@/assets/image/404.gif">
  </div> -->
  <div class='canvasContainer'>
      <div id='canvasImg'>
        <img id='canvasImgSrc' :src="imgUrl" />
      </div>

      <div class="content" id='content'>
        <h1 style='text-align: center;'>法安心“私人律师”产品电子凭证</h1>
        <div class='rightcenter'>订单编号：123123123</div>
        <div class="context">
          <div class="title">法安心“私人律师”产品电子凭证”</div>
          <div class='message'>
            <div class='item'>
              <span>客户姓名</span>
              <span>小张</span>
            </div>
          </div>
        </div>
        <div class='imgbOx'>
         <div class="img">
           <img @load='onload' src="1.png" alt="">
         </div>
         <div class="img">
           <img @load='onload' src="2.png" alt="">
         </div>
        </div>
      </div>

    </div>
</template>

<script>
export default {
  data(){
    return {
      imgUrl:'',
      num:0
    }
  },
mounted(){
    // var canvas2 = document.createElement("canvas");
    // let _canvas = document.getElementById('content');
    // // var w = parseInt(window.getComputedStyle(_canvas).width);
    // // var h = parseInt(window.getComputedStyle(_canvas).height);
    // // canvas2.width = w;
    // // canvas2.height = h;
    // // canvas2.style.width = w + "px";
    // // canvas2.style.height = h + "px";
    // // console.log(w,h)
    // // canvas2.style.display = "block";
    // // var context = canvas2.getContext("2d");
    // // context.scale(.5,.5)
    // let option = {
    // width: _canvas.clientWidth,
    // height: _canvas.clientHeight,
    // scrollY: 0, 
    // scrollX: 0,
    // useCORS: true
    // };
    // this.$nextTick(()=>{
    //   let that = this;
    //   setTimeout(() => {
    //     html2canvas(_canvas,option).then(function(canvas) {
    //       var a = document.getElementById('canvasImgSrc');
    //       // document.body.appendChild(canvas);
    //       // a.src = canvas.toDataURL();
    //       that.imgUrl = canvas.toDataURL();
    //     });
    //   }, 500);
    // })
    // this.$nextTick(()=>{
    //   this.toCanvas()
    // })
  },
  methods:{
    onload(){
      this.num = this.num + 1;
      if(this.num == 2){
        this.$nextTick(()=>{
          this.toCanvas()
        })
      }
    },
    toCanvas() {
      let dom = document.getElementById('content')
      let that = this;
      html2canvas(dom, {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      }).then(canvas => {
          that.imgUrl = canvas.toDataURL('image/png')
      });
 
    }
  }
}
</script>
<style scoped>
/* .canvasContainer{
  width:100%;
  position: relative;
  font-size: 16px;
}
#canvasImg{
  width:100%;
  margin-bottom:30px;
} */


.content{
  width:80%;
  /* margin:0 auto;
  padding:10px;
  margin-top:50px; */
  position: absolute;
  left:-9999px;
  top:-9999px
}
.rightcenter{
  text-align: right;
  margin:20px auto
}
.context{
  border:2px solid #333;
  text-align: center;
  width:100%;
  height:100%;
  margin:auto
}
.context .title{
  background: yellow;
  height:60px;
  margin:auto;
   width:100%;
  color:#333;
  line-height: 60px;
}
.imgbOx{
  overflow:hidden;
   width:100%;
  margin:auto
}
.imgbOx div{
  float: left;
  width:100px;
  height:100px;
}
.imgbOx img{
  width:100px;
  height:100px;
}
</style>
<style lang="scss" scoped>
// .notFind{
//   img{
//     position: fixed;
//     left:50%;
//     top:50%;
//     transform: translate(-50%,-50%);
//     display: block;
//     outline: none;
//     border: none;
//   }
// }
</style>